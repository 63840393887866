






































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

export default Vue.extend({
  data() {
    return {
      error: null as Error|null,
      loading: false,
      form: {
        tracking_number: '',
        carrier: null, // TODO
      },
      validation: {
        tracking_number: null,
        carrier: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/commission/Find',
      list: 'business/commission/trackers/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    business(): ApiResource.Business {
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
  },
  methods: {
    async track() {
      this.error = null;
      this.loading = true;

      await this.$store.dispatch('business/commission/trackers/Create', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
        form: this.form,
      }).catch((e) => { this.error = e; });

      this.loading = false;
      if (this.error) return;

      this.$router.push({
        name: 'dashboard.commission.trackers',
        params: this.$route.params,
      });
    },
  },
});
